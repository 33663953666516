import React from 'react';
import { Table, ListContainer } from './styles';

const EracheAndIRLDurations = () => {
    return (
        <ListContainer>
            <h3>Tableau de conversion des périodes</h3>
            <Table>
                <thead>
                    <tr>
                        <th>Période dans le calendrier erachien</th>
                        <th>Durée IRL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1 jour</td>
                        <td>20 minutes</td>
                    </tr>
                    <tr>
                        <td>1 semaine (8 jours)</td>
                        <td>2 heures et 40 minutes</td>
                    </tr>
                    <tr>
                        <td>1 mois (8 semaines)</td>
                        <td>21 heures et 20 minutes</td>
                    </tr>
                    <tr>
                        <td>1 an (16 mois)</td>
                        <td>14 jours, 5 heures et 20 minutes</td>
                    </tr>
                    <tr>
                        <td>1 octet (8 ans)</td>
                        <td>113 jours, 18 heures et 40 minutes</td>
                    </tr>
                    <tr>
                        <td>1 cycle (64 ans)</td>
                        <td>910 jours, 5 heures et 20 minutes</td>
                    </tr>
                </tbody>
            </Table>
        </ListContainer>
    );
};

export default EracheAndIRLDurations;
