import React from 'react';
import {
    HeaderContainer,
    HeaderChronofieldLogo,
    HeaderNavbar,
    //HeaderAvatar,
    NavLink
} from './Header.style'
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <HeaderContainer>
            <Link to="/home"> 
                <HeaderChronofieldLogo />
            </Link>
           
            <HeaderNavbar>
                {/*<NavLink to='/display'> Display</NavLink> */}
                <NavLink to='/erache-converter'> Convertisseur </NavLink>
                <NavLink to='/erache-infos'> Informations </NavLink>
                {/*<NavLink to='/add'> Add</NavLink>  */}
                {/*<NavLink to='/list'> List</NavLink>  */}
            </HeaderNavbar>
            {/*<HeaderAvatar />*/}
        </HeaderContainer>

    );
};

export default Header;