import React from 'react';
import { Table, ListContainer } from './styles';

const EracheMonthSeasons = () => {
    const seasons = [
        { name: 'Pacua', months: ['Pampélune', 'Fifrelune', 'Mimélune', 'Claircelune'] },
        { name: 'Vacua', months: ['Mérolia', 'Cidélia', 'Iffélia', 'Lérolia'] },
        { name: 'Hallua', months: ['Holevent', 'Agrevent', 'Tercevent', 'Démévent'] },
        { name: 'Noüa', months: ['Radévard', 'Tronvard', 'Pénavard', 'Nérévard'] },
    ];

    return (
        <ListContainer>
            <h3>Les saisons et mois du calendrier</h3>
            <Table>
                <thead>
                    <tr>
                        <th>Saison</th>
                        <th>Mois</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Générer dynamiquement les lignes de la table */}
                    {seasons.map(season => (
                        <tr key={season.name}>
                            <td>{season.name}</td>
                            <td>{season.months.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ListContainer>
    );
};

export default EracheMonthSeasons;