function DateIRLToErache(date) {
  if (date == null || date === 0) {
    return "Date invalide"
  }
  // extract h and m from the date
  var h = date.getHours();
  var m = date.getMinutes();

  //Day 1 of Erache's calendar /!\ 0 is january, 11 is December
  var dateOrigin = new Date(2010, 10, 9, 0, 0);

  //Extract day, month and year from date
  var dateToExtract = new Date(date);
  var day = dateToExtract.getDate();
  var month = dateToExtract.getMonth();
  var year = dateToExtract.getFullYear();

  if (h == null) {
    h = day % 24;
  }
  if (m == null) {
    m = (year + month + day) % 60;
  }
  //Create date to convert
  var dateToConvert = new Date(year, month, day, h, m);

  //Number of ms between the two dates
  var timeDiff = dateToConvert.getTime() - dateOrigin.getTime();

  //Number of IRL days between the two dates
  var diffDays = timeDiff / (1000 * 3600 * 24);

  /* 20min IRL = 1 day erache
     1h IRL    = 3 days erache
     24h IRL   = 72 days erache
   */
  //Number of Erache's days between the two dates
  var eracheDays = Math.floor(diffDays * 72);

  //Number of Erache's months between the two dates
  var eracheMonths = Math.floor(eracheDays / 64);

  //Number of Erache's years between the two dates
  var eracheYear = Math.floor(eracheDays / 1024);

  //Calculation of the month, there is 16 months in a year :
  var eracheMonth = eracheMonths % 16 + 1;
  var months = ["Pampélune", "Fifrelune", "Mimélune", "Claircelune", "Mérolia", "Cidélia", "Iffélia", "Lérolia", "Holevent", "Agrevent", "Tercevent", "Démévent", "Radévard", "Tronvard", "Pénavard", "Nérévard"];
  var eracheMonthName = months[eracheMonth - 1];

  //Calculation of the day, there is 64 days in a month :
  var eracheDay = eracheDays % 64 + 1

  var daysOfWeek = ["Premui", "Dezui", "Tressui", "Khatui", "Quinui", "Sizui", "Sepui", "Houi"];
  var dayOfWeek = (eracheDay - 1) % 8;
  var dayOfWeekName = daysOfWeek[dayOfWeek];

  if (timeDiff > 0) {
    return dayOfWeekName + " " + eracheDay + " " + eracheMonthName + " de l'an " + eracheYear;
  } else {
    return eracheYear + " avant Dok"
  }
}

function AgeCalculator(date) {
  if (date == null || date === 0) {
    return "Date invalide"
  }
  var currentTime = new Date();

  // Number of ms between inscription and today
  // If the date is in the future, the result will be negative
  var timeDiff = currentTime.getTime() - date.getTime();

  // Number of RP days (20min) between the two dates
  var diffDays = timeDiff / (1000 * 60 * 20);

  // Special case for today
  if (timeDiff < 10 * 60 * 1000 && timeDiff > - 10 * 60 * 1000) {
    return "Aujourd'hui"
  }

  var stringToReturn = "";
  var diffYears =  Math.floor(diffDays / 1024)
  var diffMonths =  Math.floor((diffDays % 1024) / 64)
  var diffFloorDays =  Math.floor(diffDays % 64)
  if (timeDiff < 0) { // If the date is in the future
    stringToReturn = "(Dans ";
    diffYears = Math.floor((-diffDays+1) / 1024)
    diffMonths = Math.floor(((-diffDays+1) % 1024) / 64)
    diffFloorDays = Math.floor((-diffDays+1) % 64)
  } else { // If the date is in the past
    stringToReturn = "(Il y a ";
  }

  if (diffYears !== 0) {
    if (diffYears === 1) {
      stringToReturn += diffYears + " an";
    } else {
      stringToReturn += diffYears + " ans";
    }
  }

  if (diffMonths !== 0) {
    if (diffYears !== 0) {
      stringToReturn += " et ";
    }
    stringToReturn += diffMonths + " mois";
  }

  if (diffFloorDays !== 0) {
    if (diffYears !== 0 || diffMonths !== 0) {
      stringToReturn += " et ";
    }
    // If there is only one day, we don't want to display "1 jours"
    if (diffFloorDays === 1) {
      stringToReturn += diffFloorDays + " jour";
    } else {
      stringToReturn += diffFloorDays + " jours";
    }
  }

  stringToReturn += ")";
  return stringToReturn;
}



export { DateIRLToErache, AgeCalculator };

