import React from 'react';
import styled from "styled-components"

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <h3>Chronofield.fr</h3>
                <p>Explorez le calendrier Erachien et utilisez nos outils pour enrichir votre expérience sur Minefield.fr.</p>
                <p>Ce site n'est en aucun cas lié à l'association Minefield.fr</p>
            </FooterContent>
            {/*<FooterLegal>
                <p>&copy; 2024 Chronofield.fr Tous droits réservés.</p>
                <p><a href="/politique-de-confidentialite">Politique de confidentialité</a> | <a href="/conditions-utilisation">Conditions d'utilisation</a></p>
    </FooterLegal>*/}
        </FooterContainer>   
    );
};

export default Footer;

const FooterContainer = styled.footer`
    background-color: #333;
    color: white;
    padding: 20px 0;
    display: flex;
    height: 15vh;
    justify-content: space-between; /* Align elements */
    align-items: center; /* Center vertically */
    margin-top: auto; /* Push to the bottom */
`;

const FooterContent = styled.div`
    flex: 1;
    padding: 0 20px;
`;


// const FooterLegal = styled.div`
//     p {
//         margin: 0;
//     }
//     a {
//         color: orange;
//     }
// `;
