import ChronofieldLogo from "./chronofield_logo_2.webp"
//import ChronofieldLogoSmall from "./chronofield_logo_1.webp"
import styled from "styled-components"
import { Avatar } from "@mui/material"
import { NavLink as Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    color: white;
    background-color: #f9f6f2;
    border-bottom: 1px solid #e0e0e0;
    z-index: 1000; 
`

export const HeaderChronofieldLogo = styled.img.attrs({
  src: `${ChronofieldLogo}`,
})`
  display: block;
  max-width: 30vw;
  margin-left: 20px;
  // if media smaller, change image
  
`;

export const HeaderAvatar = styled(Avatar)`
    display: flex;
    align-items: flex-end;
    background-color: white;
    cursor: pointer;
    margin-right: 20px;

    :hover {
        opacity: 0.8;
    }
`

export const HeaderNavbar = styled.div`
    display: flex;
    align-items: left; 
    margin-right: 200px;   
`

export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;