import './App.css';
import './vis-timeline-graph2d.min.css';
import './vis-timeline-graph2d.min.css.map';
//import Display from './components/Display'
import Header from './components/Header'
import Footer from './components/Footer'
import Converter from './components/Converter'
import Home from './components/Home'
import { AppBody } from './App.style'
//import Layout from './components/Layout'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom"
import EracheCalendarInfos from './components/EracheCalendarInfos';
//import EventsList from './components/EventsList';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppBody>
          <Header />
          <Routes>
            <Route path='/' element={<Navigate to="/home" replace />} />
            <Route path='/home' element={<Home />} />
            <Route path='/erache-converter' element={<Converter />} />
            <Route path='/erache-infos' element={<EracheCalendarInfos />} />
            {/* <Route path="/display" element={<Display/>} />
            <Route path="/list" element={<EventsList/>} />
            <Route path="/create" element={"create"} /> */}
            <Route path="*" element={"404"} />
          </Routes>
          <Footer />
        </AppBody>
      </BrowserRouter>
    </div>
  );
}

export default App;

