import React from 'react';
import styled from 'styled-components';
import { Table } from './styles';

// Styled component pour la liste des jours
const DayListContainer = styled.div`
    margin-top: 20px;
    margin-right: 20px;
`;

// Composant pour la liste de jours
const EracheDaysList = () => {
    const days = ['Premui', 'Dezui', 'Tressui', 'Khatui', 'Quinui', 'Sizui', 'Sepui', 'Houi'];

    return (
        <DayListContainer>
            <h3>Les jours du calendrier</h3>
            <Table>
                <thead>
                    <tr>
                        <th>Jour</th>
                    </tr>
                </thead>
                <tbody>
                    {days.map(day => (
                        <tr key={day}>
                            <td>{day}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </DayListContainer>
    );
};

export default EracheDaysList;