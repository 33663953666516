import React, { useState, useEffect } from 'react';
import { DateIRLToErache, AgeCalculator } from '../utils/dateConverters';
//import { getCurrentDate, getCurrentTime } from '../utils/dateUtils';
import styled from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import 'react-datepicker/dist/react-datepicker.css';
// use local scss instead of dist
import './Converter.scss'
import { Container } from './styles';

registerLocale("fr", fr);

const Converter = () => {
  const [date, setDate] = useState(getInitialDate()); // Initialise avec l'heure actuelle
  const [computedDate, setComputedDate] = useState('');
  const [computedAge, setComputedAge] = useState('');
  const [, setCopySuccess] = useState(false);
  const [buttonColor, setButtonColor] = useState('#7a6138');
  const [buttonText, setButtonText] = useState('Copier');

  useEffect(() => {
    updateResult(date);
  }, [date]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    updateResult(selectedDate);
  };

  const updateResult = (selectedDate) => {
    const conversionResult = DateIRLToErache(selectedDate);
    const ageResult = AgeCalculator(selectedDate);
    setComputedDate(conversionResult);
    setComputedAge(ageResult);
  };

  function getInitialDate() {
    const currentTime = new Date();
    const roundedMinutes = Math.round(currentTime.getMinutes() / 20) * 20;
    const initialDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), currentTime.getHours(), roundedMinutes);
    return initialDate;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(computedDate)
      .then(() => {
        setCopySuccess(true);
        setButtonText('Copié!');
        setButtonColor('#463821');
        // After some time, reset
        setTimeout(() => {
          setCopySuccess(false);
          setButtonColor('#7a6138');
          setButtonText('Copier');
        }, 2000);
      })
      .catch(error => console.error('Erreur lors de la copie dans le presse-papiers :', error));
  };

  return (
    <Container>
      <h1>Convertisseur de dates</h1>
      <ConverterContainer>
        <Label>Choisissez la date et l'heure à convertir:</Label>
        <DatePicker
        showIcon
        selected={date}
        showTimeSelect
        open={false}
        locale="fr"
        dateFormat="dd/MM/yyyy p"
        onChange={handleDateChange}
        
        />
        <DatePicker
          showIcon
          selected={date}
          showTimeSelect
          onChange={handleDateChange}
          locale="fr"
          dateFormat="dd/MM/yyyy p"
          timeFormat="HH:mm"
          timeIntervals={20}
          inline
        />
        

        <CalendarName>Calendrier erachien:</CalendarName>
        <Result>
          {computedDate}
          <CopyButton onClick={copyToClipboard} buttonColor={buttonColor}>{buttonText}</CopyButton>
        </Result>
        {computedAge}


      </ConverterContainer>
    </Container>
  );
};

const ConverterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; 
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Result = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  min-width: 40vw;
  justify-content: space-between;
  @media (max-width: 768px) {
    min-width: 60vw;
    flex-direction: column;
    align-items: center;
  }
`;

const CopyButton = styled.button`
  margin-left: 10px;
  margin-right: 0;
  padding: 5px 10px;
  background-color: ${props => props.buttonColor || '#7a6138'};
  color: #fff;
  border: none;
  border-radius: 5px;
  min-width: 100px;
  cursor: pointer;
  &:hover {
    background-color: #463821;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1.2em;
    &:active {
      background-color: #463821;
    }
    min-width: 30vw;
  }
`;

const CalendarName = styled.div`
  margin-top: 20px;
  font-size: 1.2em;
`;

export default Converter;