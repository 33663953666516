import React from 'react';
import EracheMonthSeasons from './EracheMonthsSeasons';
import EracheAndIrlDurations from './EracheAndIRLDurations';
import styled from 'styled-components';
import EracheDaysList from './EracheDays';
import { Container } from './styles';


const EracheCalendarInfos = () => {
    return (
        <Container>
            <h1>Informations sur le calendrier Erachien</h1>
            <ListContainer>
                <div className="col">
                    <EracheMonthSeasons />
                </div>
                <div className="col">
                    <EracheDaysList />
                </div>
                <div className="col">
                    <EracheAndIrlDurations />
                </div>
            </ListContainer>
        </Container>
    );
};

export default EracheCalendarInfos;

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;    
    justify-content: space-around;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: left;
    }
    `;