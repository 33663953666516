import React from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
`;

const Title = styled.h1`
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
`;

const Description = styled.p`
    font-size: 1.2em;
    text-align: center;
`;

const Button = styled.button`
    padding: 10px 20px;
    font-size: 1em;
    background-color: #7a6138;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #463821;
    }
`;

const Home = ({ onStartClick }) => {
    let navigate = useNavigate(); 

    const handleStartClick = () => {
        navigate('/erache-converter');
    };

    return (
        <HomeContainer>
            <Title>Bienvenue sur Chronofield</Title>
            <Description>
                Chronofield est une suite d'outils liée aux calendriers utilisés sur le serveur Minefield.fr
            </Description>
            <Button onClick={handleStartClick}>Commencer</Button>
        </HomeContainer>
    );
};

export default Home;